<script setup lang="ts">
import { SHIPPING_METHODS_LIST } from "~/lib/constants";
import type { ShippingMethod } from "@shopware-pwa/types";
import { PickupLocationPrompt } from "#components";

const isOpen = ref(false);
const isLoading = ref(false);

const {
	shippingMethods,
	selectedShippingMethod: shopwareSelectedShippingMethod,
	setShippingMethod: setShopwareShippingMethod
} = useShopwareCheckout();

const selectedShippingMethodId = ref(shopwareSelectedShippingMethod.value?.id);
const { showDrawer } = useDrawer();

const open = () => {
	isOpen.value = true;
};

defineExpose({
	open
});

const getShippingMethod = (id: string) => SHIPPING_METHODS_LIST.find((method) => method.shopwareId === id);

const setShippingMethod = async (method: ShippingMethod) => {
	selectedShippingMethodId.value = method.id;
	await setShopwareShippingMethod(method.id);
};

const close = () => {
	isOpen.value = false;
};

const openPickupLocationSelector = () => {
	showDrawer(PickupLocationPrompt, {
		drawerTitle: "Selecteer je afhaallocatie"
	});
};

watch(shopwareSelectedShippingMethod, (shippingMethod) => {
	selectedShippingMethodId.value = shippingMethod?.id;
});
</script>
<template>
	<div>
		<KippieSidebar v-model="isOpen" placement="right" color="white">
			<template #top>
				<div>
					<Typography variant="h2">Bezorgen of afhalen?</Typography>
				</div>
			</template>
			<div class="flex flex-col gap-4">
				<KippieCard
					v-for="method in shippingMethods"
					:key="method.id"
					tabindex="0"
					class="cursor-pointer p-6"
					:class="{
						'border border-brown': selectedShippingMethodId !== method.id
					}"
					@click="setShippingMethod(method)"
				>
					<template #icon>
						<SvgIcon
							v-if="getShippingMethod(method.id)?.type === 'delivery'"
							name="delivery"
							class="w-[24px]"
						/>
						<SvgIcon v-else name="store" class="w-[24px]" />
					</template>

					<div class="flex flex-col">
						<span class="font-bold">
							{{ method.name }}
						</span>
						<span class="text-sm">{{ method.description }}</span>
					</div>
				</KippieCard>
				<div v-if="selectedShippingMethodId">
					<KippieButton
						v-if="getShippingMethod(selectedShippingMethodId)?.type === 'pickup'"
						@click="
							close();
							openPickupLocationSelector();
						"
						size="small"
						class="w-full"
						:disabled="isLoading"
					>
						<span> Winkel kiezen </span>
					</KippieButton>
					<KippieButton v-else @click="close" size="small" class="w-full" :disabled="isLoading">
						<span> Verder winkelen </span>
					</KippieButton>
				</div>
			</div>
		</KippieSidebar>
	</div>
</template>
